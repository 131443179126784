import { ref, onMounted, onUnmounted } from 'vue'

const useFilterPopup = (xoffset = 0, yoffset = 0) => {
  const btn = ref(null)
  const popup = ref(null)
  const isOpen = ref(false)

  const defaultY = 26

  const setPopupPos = () => {
    // 根據 btn 設定 popup 位置
    popup.value.style.left =
      btn.value.getBoundingClientRect().left + xoffset + 'px'
    popup.value.style.top =
      btn.value.getBoundingClientRect().bottom + defaultY + yoffset + 'px'
  }

  const togglePopup = () => {
    if (!isOpen.value) {
      setPopupPos()
    }
    isOpen.value = !isOpen.value
  }

  const onClick = e => {
    if (!btn.value.contains(e.target) && !popup.value.contains(e.target)) {
      // 點擊外部區域，將關閉 popup
      isOpen.value = false
    }
  }

  onMounted(() => {
    window.addEventListener('click', onClick)
  })

  onUnmounted(() => {
    window.removeEventListener('click', onClick)
  })

  return {
    isOpen,
    togglePopup,
    btn,
    popup
  }
}

export default useFilterPopup
