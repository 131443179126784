<template>
  <div class="filter-unit" ref="btn" @click="togglePopup">
    <span class="filter-unit-key">{{ keyName }}</span>
    <span class="filter-unit-operator">==</span>
    <span class="filter-unit-value">{{ displayValue }}</span>
  </div>

  <teleport to="#popup-container">
    <div
      class="filter-popup no-hr-padding"
      ref="popup"
      v-show="isOpen"
      style="max-width: 366px; min-width: 182px;"
    >
      <div class="triangle"></div>
      <h5 class="title">{{ keyName }}</h5>
      <slot />
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import useFilterPopup from '@/composable/useFilterPopup'

export default defineComponent({
  props: {
    keyName: String,
    allChecked: Boolean,
    checkedIds: Array
  },
  setup(props) {
    const { isOpen, togglePopup, btn, popup } = useFilterPopup()
    const displayValue = computed(() => {
      if (props.allChecked) {
        return '全選'
      } else if (props.checkedIds && props.checkedIds.length > 1) {
        return '多選'
      } else if (props.checkedIds && props.checkedIds.length > 0) {
        return '單選'
      }
      return '空值'
    })
    return {
      isOpen,
      togglePopup,
      btn,
      popup,
      displayValue
    }
  }
})
</script>

<style scoped></style>
