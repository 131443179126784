
import { defineComponent } from 'vue'

// v-model on the custom component
// CHECK: (https://v3.vuejs.org/guide/migration/v-model.html#_3-x-syntax)

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: '查詢'
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue', 'submitFilter'],
  setup() {
    return {}
  }
})
