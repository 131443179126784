import { ref } from 'vue'

// 搭配 FilterUnitCheckbox.vue 元件和裡面的 checkboxes 使用
const useFilterCheckbox = (checkboxOptions, initialCheckedIds) => {
  // 是否全選
  const allChecked = ref(false)
  // 被選中的選項
  const checkedIds = ref(initialCheckedIds)

  // 單選
  const onCheck = () => {
    allChecked.value = false
  }
  // 全選
  const checkAll = () => {
    checkedIds.value = []
    if (allChecked.value) {
      checkboxOptions.forEach(option => {
        checkedIds.value.push(option.id.toString())
      })
    }
  }
  return {
    allChecked,
    checkedIds,
    onCheck,
    checkAll
  }
}

export default useFilterCheckbox
