<template>
  <div class="filter-unit add" ref="btn" @click="togglePopup">
    <SvgIcon :icon="titleName == '篩選' ? 'filter' : 'plus'" />
    <span class="filter-unit-value">加入{{ titleName }}</span>
  </div>

  <teleport to="#popup-container">
    <div class="filter-popup" ref="popup" v-show="isOpen" style="width: 321px;">
      <div class="triangle"></div>
      <h5 class="title">加入{{ titleName }}</h5>
      <div class="filter-field">
        <span class="filter-field-name">{{ titleName }}</span>
        <DropdownList
          :items="getKeys"
          :defaultValue="'請選擇' + titleName"
          @change="onKeyChange"
          :key="componentKey"
        />
      </div>
      <div class="filter-field">
        <span class="filter-field-name">運算子</span>
        <DropdownList
          :items="['==', '!=']"
          :defaultValue="'=='"
          @change="onOperatorChange"
        />
      </div>
      <div class="filter-field">
        <span class="filter-field-name">值</span>
        <DropdownList
          v-if="isKeyHasOptions"
          :items="getOptions"
          :defaultValue="'請選擇值'"
          @change="onDropdownValueChange"
        />
        <input
          v-else
          class="form-control filter-popup-input"
          type="text"
          :value="currentValue"
          @input="onInputValueChange"
        />
      </div>
      <div class="divider"></div>
      <button
        class="btn btn-primary filter-btn filter-btn-apply"
        :disabled="isBtnDisabled"
        @click="addFilterUnit"
      >
        套用
      </button>
      <button
        class="btn btn-outline-light filter-btn filter-btn-cancel"
        @click="cancel"
      >
        取消
      </button>
    </div>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import useFilterPopup from '@/composable/useFilterPopup'
import DropdownList from '@/components/Dropdown/DropdownList.vue'

export default defineComponent({
  components: {
    DropdownList
  },
  props: {
    // 可用的篩選選項
    keyWithOptions: Array,
    // 標題，針對不同情境使用，例如：加入'設備條件'
    titleName: {
      type: String,
      default: '篩選'
    }
  },
  emits: ['addFilterUnit'],
  setup(props, ctx) {
    const { isOpen, togglePopup, btn, popup } = useFilterPopup()
    // 1. 運算子
    const currentOperator = ref('==')
    // 紀錄運算子變化
    const onOperatorChange = item => (currentOperator.value = item)

    // 2. 篩選 key
    const currentKey = ref('')
    // 紀錄篩選 Key 變化
    const onKeyChange = item => (currentKey.value = item)

    //@ts-ignore // 取得所有 keys
    const getKeys = computed(() => props.keyWithOptions.map(item => item.key))
    // 目前 key 是否帶選項
    const isKeyHasOptions = computed(() => {
      let result = false
      //@ts-ignore
      for (let i = 0; i < props.keyWithOptions.length; i++) {
        // 為目前 key 且 options array 有值
        if (
          //@ts-ignore
          props.keyWithOptions[i].key == currentKey.value &&
          //@ts-ignore
          props.keyWithOptions[i].options.length > 0
        ) {
          result = true
          break
        }
      }
      return result
    })
    // 取得所有選項
    const getOptions = computed(() => {
      //@ts-ignore
      for (let i = 0; i < props.keyWithOptions.length; i++) {
        //@ts-ignore
        if (props.keyWithOptions[i].key == currentKey.value) {
          //@ts-ignore
          return props.keyWithOptions[i].options
        }
      }
      return []
    })

    // 3. 值
    const currentValue = ref('')
    // 追蹤 key 更新時，自動重設值為 ''，包含切換 key 或強制更新 key 的 Dropdown
    watch(currentKey, () => {
      currentValue.value = ''
    })
    // 紀錄值變化
    const onDropdownValueChange = item => (currentValue.value = item)
    const onInputValueChange = e => (currentValue.value = e.target.value)

    // 4. 強制重整 component
    const componentKey = ref(0)
    const forceRerender = () => {
      // 強制重整 key 的 Dropdown 元件
      componentKey.value += 1
    }
    const resetCurrent = () => {
      // 重設 key 值，此操作會觸發重設 currentValue
      currentKey.value = ''
    }

    // 5. 套用
    // 套用按鈕：狀態
    const isBtnDisabled = computed(
      () => currentKey.value == '' || currentValue.value == ''
    )
    // 加入新的 filterUnit
    const addFilterUnit = () => {
      const newFilterUnit = {
        id: new Date().getUTCMilliseconds(),
        key: currentKey.value,
        operator: currentOperator.value,
        value: currentValue.value,
        options: getOptions.value
      }
      // 發射新的 filterUnit
      ctx.emit('addFilterUnit', newFilterUnit)
      isOpen.value = false // 關閉 popup
      resetCurrent() // 重設所有紀錄值
      forceRerender() // 強制重整 key 的 Dropdown 元件
    }
    // 取消目前的加入
    const cancel = () => {
      isOpen.value = false
      resetCurrent()
      forceRerender()
    }

    return {
      isOpen,
      togglePopup,
      btn,
      popup,
      onOperatorChange,
      onKeyChange,
      currentKey,
      getKeys,
      isKeyHasOptions,
      getOptions,
      currentValue,
      onDropdownValueChange,
      onInputValueChange,
      isBtnDisabled,
      addFilterUnit,
      cancel,
      componentKey
    }
  }
})
</script>

<style scoped></style>
