
import { defineComponent, computed } from 'vue'
import useFilterPopup from '@/composable/useFilterPopup'

export default defineComponent({
  props: {
    keyName: String,
    allChecked: Boolean,
    checkedIds: Array
  },
  setup(props) {
    const { isOpen, togglePopup, btn, popup } = useFilterPopup()
    const displayValue = computed(() => {
      if (props.allChecked) {
        return '全選'
      } else if (props.checkedIds && props.checkedIds.length > 1) {
        return '多選'
      } else if (props.checkedIds && props.checkedIds.length > 0) {
        return '單選'
      }
      return '空值'
    })
    return {
      isOpen,
      togglePopup,
      btn,
      popup,
      displayValue
    }
  }
})
