
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    skip: Number,
    take: Number,
    total: Number
  },
  setup() {
    return {}
  }
})
