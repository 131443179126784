
import { defineComponent, ref, computed } from 'vue'
import DropdownList from '@/components/Dropdown/DropdownList.vue'
import useFilterPopup from '@/composable/useFilterPopup'

export default defineComponent({
  components: {
    DropdownList
  },
  props: {
    filterUnit: Object
  },
  emits: ['updateFilterUnit', 'deleteFilterUnit'],
  setup(props, ctx) {
    const { isOpen, togglePopup, btn, popup } = useFilterPopup()

    //@ts-ignore
    const currentOperator = ref(props.filterUnit.operator) // 紀錄目前選取的運算子
    //@ts-ignore
    const currentValue = ref(props.filterUnit.value) // 紀錄目前選取 or 輸入的值
    const onOperatorChange = item => (currentOperator.value = item)
    const onDropdownValueChange = item => (currentValue.value = item)
    const onInputValueChange = e => (currentValue.value = e.target.value)

    // 套用按鈕狀態
    const isBtnDisabled = computed(() => {
      if (props.filterUnit) {
        return (
          props.filterUnit.operator == currentOperator.value &&
          props.filterUnit.value == currentValue.value
        )
      }
      return true
    })
    const cancelSelected = () => {
      isOpen.value = false
    }
    const updateFilterUnit = () => {
      // 更新 filterUnit
      if (props.filterUnit) {
        ctx.emit('updateFilterUnit', {
          id: props.filterUnit.id,
          key: props.filterUnit.key,
          operator: currentOperator.value,
          value: currentValue.value,
          options: props.filterUnit.options
        })
      }
      isOpen.value = false
    }
    const deleteFilterUnit = () => {
      // 刪除此 filterUnit
      if (props.filterUnit) {
        ctx.emit('deleteFilterUnit', props.filterUnit.id)
      }
    }

    return {
      // Popup 相關
      isOpen,
      togglePopup,
      btn,
      popup,
      // Dropdown 相關
      onOperatorChange,
      onDropdownValueChange,
      onInputValueChange,
      currentValue,
      isBtnDisabled,
      cancelSelected,
      // 資料相關
      updateFilterUnit,
      deleteFilterUnit
    }
  }
})
