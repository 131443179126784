<template>
  <div class="input-group filter-input-group">
    <input
      type="text"
      class="form-control filter-input"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary filter-submit-btn"
        type="button"
        @click="$emit('submitFilter')"
      >
        <SvgIcon icon="search" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// v-model on the custom component
// CHECK: (https://v3.vuejs.org/guide/migration/v-model.html#_3-x-syntax)

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: '查詢'
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue', 'submitFilter'],
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
