import { ref } from 'vue'

// 搭配 FilterUnitDeletable.vue, FilterUnitAdd.vue 元件使用
const useFilterUnitList = initialFilterUnitList => {
  // 自訂篩選組列表
  const filterUnitList = ref(initialFilterUnitList)

  // 根據 id 更新自訂篩選組
  const updateFilterUnit = payload => {
    console.log('updateFilterUnit')
    filterUnitList.value = filterUnitList.value.map(filterUnit => {
      return filterUnit.id === payload.id ? payload : filterUnit
    })
  }

  // 根據 id 刪除自訂篩選組
  const deleteFilterUnit = targetId => {
    console.log('deleteFilterUnit')
    filterUnitList.value = filterUnitList.value.filter(
      filterUnit => filterUnit.id !== targetId
    )
  }

  // 加入新的自訂篩選組
  const addFilterUnit = newFilterUnit => {
    console.log('addFilterUnit')
    filterUnitList.value.push(newFilterUnit)
  }

  return {
    filterUnitList,
    updateFilterUnit,
    deleteFilterUnit,
    addFilterUnit
  }
}

export default useFilterUnitList
