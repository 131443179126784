<template>
  <div class="filter-row-wrapper">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
