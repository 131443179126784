<template>
  <p class="page-info">
    正在顯示 <span>{{ skip + 1 }}</span> 至
    <span>{{ take + skip }}</span> 筆記錄 (共 <span>{{ total }}</span> 筆)。
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    skip: Number,
    take: Number,
    total: Number
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
